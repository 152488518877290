<div class="attachment">
  <button class="btn attach">
    <i class="material-icons md-18">insert_drive_file</i>
  </button>
  <div class="download-doc">
    <div class="downloader">
      <div class="file-size">{{size? (size | byteformatter) : ''}}</div>
    </div>
  </div>
  <div class="file">
    <h5>
      {{fileName}}
    </h5>
  </div>
  <div class="file-progress">
    <app-downloader [name]="name" [source]="source"></app-downloader>
  </div>
</div>
