import { environment } from './../../../environments/environment';
import { TimeHMS } from './../../entity/models/timehms';
import { MediaService } from './../../services/media.service';
import { AudioStatus } from './../../entity/enums/audio-status';
import { Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss']
})
export class AudioPlayerComponent implements OnInit, AfterViewInit {

  @ViewChild('nativeaudio', { read: ElementRef }) nativeaudio;
  @Input() name: string;
  @Input() size: number;
  @Input() duration: TimeHMS;
  @Input() audioSource: string;

  AudioStatus: typeof AudioStatus = AudioStatus; // for html bindings
  playerStatus: AudioStatus;
  playerProgress = 0;
  constructor(
    private mediaService: MediaService) {

  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.statusListner();
  }


  playNow() {
    const audio = this.nativeaudio.nativeElement;
    if (audio.paused || audio.ended) {
      this.play();
    } else {
      this.pause();
    }
  }

  play() {
    const audio = this.nativeaudio.nativeElement;
    if (audio.paused) {
      audio.play();
    } else if (audio.ended) {
      audio.currentTime = 0;
      audio.play();
    }
  }

  pause() {
    const audio = this.nativeaudio.nativeElement;
    audio.pause();
    if (audio.duration > 0 && !audio.paused) {
    } else {
    }
  }


  statusListner() {

    const audio = this.nativeaudio.nativeElement;
    audio.addEventListener('playing', () => {
      this.playerStatus = AudioStatus.PLAYING;
      console.log('playing');
    }, true);

    audio.addEventListener('pause', () => {
      this.playerStatus = AudioStatus.PAUSED;
      console.log('pause');
    }, true);

    const onTimeUpdate = fromEvent(audio, 'timeupdate');
    onTimeUpdate.subscribe(x => {
      const progress = Number(((audio.audioSource) / audio.duration * 100));
      this.playerProgress = progress;
    });

  }

}

