import { BaseMessage } from './base-message';
import { Message } from './message';
import { Client } from './user';
export class MessageRepo {
  conversationId: number;
  active: boolean = true;
  user: Client;
  messages: Array<BaseMessage> = [];
  dirty: boolean = false; // has loaded messages
  currentMessagePage = 1;
  totalMessages;

}
