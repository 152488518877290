import { DOCUMENT_TYPES } from './../../entity/enums/document-types';
import { MediaResource, UPLOADSTATUS } from './../../entity/models/media-resource';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit {

  @Input() resource: MediaResource;
  @Output() mediaRemoved: EventEmitter<void> = new EventEmitter();

  UPLOADSTATUS = UPLOADSTATUS;
  DOCUMENT_TYPES = DOCUMENT_TYPES; //html bindings

  constructor() {

  }

  ngOnInit(): void {

  }

  removeMedia() {
    console.log('remove media');
    this.mediaRemoved.emit();
  }

  close(): void {

  }

  upload() {

  }

  startPreloader(): void {

  }

  stopPreloader(): void {
  }


}
