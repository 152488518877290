import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, regexValue: string, replaceValue: string): any {
    console.log(value + " - " + regexValue + " - " + replaceValue + " - "+value.replace(regexValue, replaceValue));
    return value.replace(regexValue, replaceValue);
  }


}
