import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-manager',
  templateUrl: './search-manager.component.html',
  styleUrls: ['./search-manager.component.css']
})
export class SearchManagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
