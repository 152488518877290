import { TemplateService } from './../../services/template.service';
import { TemplateFormComponent } from './../../components/template-form/template-form.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Template } from 'src/app/entity/models/template';

@Component({
  selector: 'app-template-manager',
  templateUrl: './template-manager.component.html',
  styleUrls: ['./template-manager.component.scss']
})
export class TemplateManagerComponent implements OnInit {
  welcomeMessageModelReference: BsModalRef;
  templates: Array<Template> = [];

  constructor(private modalService: BsModalService,
    private templateService: TemplateService) { }

  ngOnInit(): void {
    this.fetchTemplates();
  }

  openForm() {
    this.welcomeMessageModelReference = this.modalService.show(TemplateFormComponent, {
      class: 'test',
      // initialState: {
      //   contact: contact
      // }
    });
  }

  fetchTemplates() {
    this.templateService.getTemplates().subscribe(res => {
      console.log('template res ', res);
      this.templates = res;
    });
  }

}
