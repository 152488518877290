import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-line-form',
  templateUrl: './line-form.component.html',
  styleUrls: ['./line-form.component.css']
})
export class LineFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
