<div class="content-wrapper">
  <main>
    <div class="layout">
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center activity">
              <button type="button" (click)="openForm()" class="btn button w-100">Add Template</button>
            </div>
            <div class="mt-3">

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Content</th>
                    <th scope="col">Welcome?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let template of templates">
                    <td>{{template.name}}</td>
                    <td>{{template.content}}</td>
                    <td>{{template.isWelcome?'yes':'no'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
