import { environment } from './../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthSocketService extends Socket {

  // Socket authentication

  constructor(
    private authService: AuthService
  ) {
    super({
      url: environment.SOCKET_ENDPOINT, options: {
        query: {
          token: authService.getToken()
        }
      }
    });

    console.log('query init ', this.ioSocket.query);
  }

}

