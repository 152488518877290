import { TelegramRequest } from './../entity/interfaces/telegram-integation-request';
import { INTEGRATION_STATUS } from './../entity/enums/intergration-status';
import { CHANNELS } from './../entity/enums/channels';
import { WhatsappRequest } from './../entity/interfaces/whatsapp-intergration-request';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { endpoints } from '../entity/consts/api-endpoints';
import stringInject from 'stringinject';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(private http: HttpClient) {

  }

  sendRequest(request: WhatsappRequest) {
    const postEndPoint = endpoints.intergrateRequestWhatsapp;
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.post(apiUrl, request);
  }

  sendTelegramRequest(request: TelegramRequest) {
    const postEndPoint = endpoints.intergrateRequestTelegram;
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.post(apiUrl, request);
  }

  getIntegation(channelName: CHANNELS) {
    const postEndPoint = stringInject(endpoints.integration, { channelName });
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get(apiUrl);
  }

  getStatus(channelName: CHANNELS) {
    const postEndPoint = stringInject(endpoints.integrationStatus, { channelName });
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get(apiUrl);
  }

  getApiKey() {
    const postEndPoint = stringInject(endpoints.apiKey);
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.get<{ status: INTEGRATION_STATUS, APIkey: string }>(apiUrl);
  }

  generateApiKey() {
    const postEndPoint = stringInject(endpoints.apiKey);
    const apiUrl = environment.BASE_URL + '/' + postEndPoint;
    return this.http.post<{ status: INTEGRATION_STATUS, APIkey: string }>(apiUrl, {});
  }

}
