import { Profile } from './../entity/models/profile';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { endpoints } from './../entity/consts/api-endpoints';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import stringInject from 'stringinject';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {


  constructor(private http: HttpClient) {

  }

  getProfile(profileId: number) :Observable<Profile>{
    const postEndPoint = stringInject(endpoints.getProfile, { profileId });
    return this.http.get(environment.BASE_URL + '/' + postEndPoint) as Observable<Profile>;
  }

  updateProfile(formData: any) {
    return this.http.put(environment.BASE_URL  + '/' + endpoints.updateProfile, formData);
  }


}

