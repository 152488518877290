<div class="redesign">
  <div class="player-wrap">
    <div class="left">
      <button type="button" class="btn btn-default btn-circle" (click)="playNow()">
        <div *ngIf="playerStatus!=AudioStatus.PLAYING" class="play-control">
          <i class="material-icons">play_arrow</i>
        </div>

        <div *ngIf="playerStatus==AudioStatus.PLAYING" class="play-control">
          <i class="material-icons">pause</i>
        </div>
      </button>
    </div>
    <div class="middle">
      <div class="music-info">
        <div class="music-name">{{name}}</div>
        <div class="music-wave">
          <div class="wave-view">

          </div>
          <div class="duration-view">
            {{duration | duration}} </div>
        </div>
      </div>
      <div class="progress-view">
        <progressbar [value]="playerProgress"></progressbar>
      </div>
    </div>
    <div class="right">
      <div class="audio-downloader">
        <div class="downloader">
          <div class="file-size">{{size | byteformatter}}</div>
          <div class="file-progress">
            <app-downloader [name]="name" [source]="audioSource"></app-downloader>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<audio [src]="audioSource" class="audioTag" #nativeaudio>
  Audio is not supported.
</audio>
