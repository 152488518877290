import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent implements OnInit {
  @Input() source: string;
  @Input() name: string = "Title";
  @Input() size: number;
  public fileName: string;

  constructor() { }

  ngOnInit(): void {
    this.getNameOnly();
  }

  getNameOnly() {
    const indexOfLastSlash = this.name.lastIndexOf('/');
    this.fileName = this.name.substring(indexOfLastSlash + 1);
  }

}
