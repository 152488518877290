import { UserSession } from './../entity/models/user-session';
import { MESSAGE_TYPES } from './../entity/enums/message-types';
import { ContactManagerComponent } from './../components/contact-manager/contact-manager.component';
import { ChatBoxService } from './../services/chatbox.service';
import { TemplateContent } from './../entity/models/templateContent';
import { BaseMessage } from './../entity/models/base-message';
import { UserService } from './../services/user.service';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaResource, UPLOADSTATUS } from '../entity/models/media-resource';
import { Client } from '../entity/models/user';
import { environment } from 'src/environments/environment';
import { MessageStatus } from '../entity/enums/message-status';
import { ChatViewService } from '../chat-view/chat-view.service';
import { CHANNELS } from '../entity/enums/channels';
import { DOCUMENT_TYPES } from '../entity/enums/document-types';
import { FileTypeHelper } from '../helpers/file-helper';
import { VideoHelper } from '../helpers/video-helper';
import { FileService } from '../services/file.service';
import { ToastrService } from 'ngx-toastr';
import { MessageContentMedia } from '../entity/models/message-content/message-content-media';
import { TemplateData } from '../entity/models/template-data';
import { MessageContentTemplate } from '../entity/models/message-content/message-content-template';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TemplateEditorComponent } from '../components/template-editor/template-editor.component';
import { MessageDirection } from '../entity/enums/message-direction';
import { MessageService } from '../services/message.service';
import { MessageRepo } from '../entity/models/message-repo';
import { NgxSpinnerService } from "ngx-spinner";
import { ChatArchiveRequest } from '../entity/models/chat-archive-request';
import { ContactsService } from './../services/contacts.service';

@Component({
  selector: 'app-chat-box',
  templateUrl: './chat-box.component.html',
  styleUrls: ['./chat-box.component.scss']
})
export class ChatBoxComponent implements OnInit {

  chatId: number;
  private chatIdSubscription: any;
  emojViewActive: boolean = false;
  mediaResource: Array<MediaResource> = [];
  client: Client;
  messageText: string;
  messageTemplate: TemplateData;
  templateModelReference: BsModalRef;
  messageRepo: MessageRepo;
  empty: boolean = false;
  @ViewChild('chatInput', { static: false, read: ElementRef }) chatInput: ElementRef;
  supportTemplate: boolean = false;

  @ViewChild('scrollMe', { static: false, read: ElementRef }) messageScroller: ElementRef;
  scrollerTimer;
  messageListnersubscription: any;
  chatArchiveRequest = new ChatArchiveRequest();
  @Output() contactDetails: EventEmitter<any> = new EventEmitter();

  constructor(private route: ActivatedRoute,
    private clientService: UserService,
    private chatService: ChatViewService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private messageService: MessageService,
    private fileService: FileService,
    public chatBoxService: ChatBoxService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private contactsService: ContactsService) {

  }

  ngOnInit(): void {
    this.chatIdSubscription = this.route.firstChild.params.subscribe(params => {
      const param = params['chatId'];
      if (param !== "start") {
        this.empty = false;
        this.chatId = +params['chatId'];
      } else {
        this.empty = true;
      }
      console.log('subscription triggered ', this.chatId);
      this.init();
    });
  }

  ngOnDestroy() {
    this.chatIdSubscription?.unsubscribe();
    this.messageListnersubscription?.unsubscribe();
  }

  init() {
    // this.spinner.show();

    if (this.empty) {
      return;
    }
    this.clientService.getClient(this.chatId).subscribe(res => {
      this.client = res;
      this.initRepo();
      this.setSupportFeatures();
      this.listneToIncommingMessages();
    });
  }


  listneToIncommingMessages() {
    this.messageListnersubscription = this.chatBoxService.getMessageRecievedListner().subscribe(message => {
      // Change archived status of specific chat to unarchive when a reply came from the client
      this.chatArchiveRequest.conversationId = message.conversationId;
      this.chatArchiveRequest.archived = message.archived;
      this.scrollToBottom();
    });
  }

  initRepo() {
    this.chatBoxService.initRepo(this.client);
    this.chatBoxService.featchPage().subscribe(res => {
      this.scrollToBottom();
    });
  }

  setSupportFeatures() {
    // only whatsapp support templates
    if (this.client.channelName == CHANNELS.WHATSAPP) {
      this.supportTemplate = true;
    } else {
      this.supportTemplate = false;
    }

  }

  onScroll() {
    // const isInside = this.isLastMessageInsideChatView();
    // this.showHideFlyMessageCounter(!isInside);
    // if (isInside) {
    //   this.resetFlyCount();
    // }

  }

  getClientData() {
    this.clientService.getClient(this.chatId);
  }

  messageScroll() {
    console.log('Message Scroll!!');
    this.spinner.show();
    this.chatBoxService.featchPage().subscribe(res => { }, err => { }, () => {
      this.spinner.hide();
    });
    // this.fetchNextMessagePage(this.activeMessageRepo);
  }

  sendMessage() {
    if (!this.mediaValid()) {
      this.toastr.error('Some files are still being uploaded.Please wait...', 'Media Exception');
      return;
    }
    const message = new BaseMessage();

    if (this.mediaResource.length > 0) {
      message.type = MESSAGE_TYPES.MEDIA;
      const resource = this.mediaResource[0];
      const mediaContent = new MessageContentMedia();
      mediaContent.attachmentId = resource.attachmentId;
      mediaContent.documentType = resource.docType;
      mediaContent.cloudURL = resource.cloudUrl;
      mediaContent.thumbnail = resource.thumbnailCloud;
      mediaContent.size = resource.size;
      (<MessageContentMedia>message.content) = mediaContent;

    } else if (this.messageTemplate) {
      message.type = MESSAGE_TYPES.TEMPLATE;
      const templateContent = new MessageContentTemplate();
      templateContent.templateId = this.messageTemplate.templateId;
      templateContent.templateFields = this.messageTemplate.templateFields;
      templateContent.text = this.messageTemplate.text;
      (<MessageContentTemplate>message.content) = templateContent;
      templateContent.renderedText = this.parseTemplate(<MessageContentTemplate>message.content);
    }
    else {
      message.content = this.messageText;
      message.type = MESSAGE_TYPES.TEXT;
    }

    message.status = MessageStatus.PENDING;
    // message.datetime = new Date();
    message.chatNumber = this.client.chatNumber;
    message.channel = this.client.channelName;
    message.direction = MessageDirection.SENT;

    this.chatService.sendMessage(message).subscribe((res: any) => {
      message.messageId = res.messageId;
      message.time = new Date(res.time);
    }, err => {
      message.status = MessageStatus.FAILED;
    });
    this.chatBoxService.addSentMessage(message);
    this.cleanMessageState();
    this.scrollToBottom();
  }

  parseTemplate(content: MessageContentTemplate) {
    const text = content.text;
    const values = content.templateFields;
    //temp
    // let curlyRegex = /{\{\d+\}\}/g;
    let curlyRegex = /\{{.*?\}}/igm;
    let newRegex = /({{[^{]*?)\w(?=\})}}/igm;
    // let matches = this.templateText.match(curlyRegex);
    // console.log("matches ", matches);
    const splitWords = text.split(curlyRegex);
    const templatePlaceholders = text.match(newRegex);
    return this.renderTemplate(splitWords, values, templatePlaceholders);
  }

  renderTemplate(splitWords: Array<string>, values: Array<string>, templatePlaceholders: Array<string>) {
    // this.templateRenderedText =
    let text = "";
    const wordLength = splitWords.length;
    const placeholdersLength = (templatePlaceholders) ? templatePlaceholders.length : 0;
    splitWords.forEach((word, i) => {
      // skip last iteration
      const isLast = (i === wordLength - 1);
      const value = values[i];
      let placeholder = "";
      if (i <= placeholdersLength-1) {
        placeholder = templatePlaceholders[i];
      }
      text += word + ((value && !isLast) ? value : placeholder);
    });
    console.log("render ", text);
    return text;
  }

  clearChat() {
    this.messageText = '';
  }

  cleanMessageState() {
    this.messageTemplate = null;
    this.mediaResource = [];
    this.clearChat();
    this.closeEmoji();
  }

  browseTemplates() {
    console.log("Browser");
    // Way to pass variables into modal
    const initialState = {
      windowOpen: this.chatBoxService.windowOpen
    };
    this.templateModelReference = this.modalService.show(TemplateEditorComponent, {initialState});
    this.templateModelReference.content.closeBtnName = 'Close';
    this.templateModelReference.content.passTemplate.subscribe((value) => {
      console.log("template recieved ", value);
      this.messageTemplate = value;
      this.sendMessage();
      this.templateModelReference.hide();
    });
  }

  processFile(imageInput: any): void {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      const resource = new MediaResource();
      resource.localFile = file;
      resource.localUrl = event.target.result;
      resource.status = UPLOADSTATUS.PENDING;
      resource.docType = FileTypeHelper.getDocumentType(file.type);

      if (resource.docType === DOCUMENT_TYPES.VIDEO) {
        VideoHelper.getVideoCover(file).then(dataUrl => {
          resource.thubnail = dataUrl;
        }).catch();
      }
      this.mediaResource = [];
      this.mediaResource.push(resource);

      this.fileService.uploadImage(file).subscribe(
        (res) => {
          resource.status = UPLOADSTATUS.SUCCESS;
          resource.name = res.name;
          resource.cloudUrl = res.cloudURL;
          resource.thumbnailCloud = res.thumbnail;
          resource.duration = res.duration;
          resource.size = res.size;
          if (res.attachmentId) {
            resource.attachmentId = res.attachmentId;
          }
        },
        (err) => {
          resource.status = UPLOADSTATUS.FAIL;
        }, () => {
        });
    });

    reader.readAsDataURL(file);
  }

  removeMedia() {
    console.log('remove plz');
    this.mediaResource = [];
  }


  mediaValid(): boolean {
    if (this.mediaResource.length === 0 || (this.mediaResource[0].status === UPLOADSTATUS.SUCCESS)) {
      return true;
    }
    return false;
  }

  openEmoji() {
    this.emojViewActive = true;
    this.chatInput.nativeElement.focus();
  }

  addEmoji($event) {
    console.log("emoji ", $event);
    const emoji = $event.emoji.native;
    this.insertEmoji(emoji);
  }

  insertEmoji(newText) {
    var start = this.chatInput.nativeElement.selectionStart;
    var end = this.chatInput.nativeElement.selectionEnd;
    var text = this.chatInput.nativeElement.value;
    var before = text.substring(0, start);
    var after = text.substring(end, text.length);
    // this.chatInput.nativeElement.value = (before + newText + after);
    this.chatInput.nativeElement.selectionStart = this.chatInput.nativeElement.selectionEnd = start + newText.length;
    this.chatInput.nativeElement.focus();
    this.messageText = (before + newText + after);
    return false;
  }

  closeEmoji() {
    this.emojViewActive = false;
  }

  private scrollToBottom(): void {

    // if (this.disableScrollDown) {
    //     return
    // }
    if (this.scrollerTimer) {
      clearTimeout(this.scrollerTimer);
    }
    this.scrollerTimer = setTimeout(() => {
      try {
        console.log(this.messageScroller.nativeElement.scrollTop, this.messageScroller.nativeElement.scrollHeight);
        this.messageScroller.nativeElement.scrollTop = this.messageScroller.nativeElement.scrollHeight;
      } catch (e) {

      }
    }, 100);

  }

  /**
   * Archive selected chat
   * @param archived 
   */
  archiveChat(archived: boolean) {
    this.chatService.archiveChat(this.client.conversationId, archived).subscribe((res: any) => {
      if (res.affected > 0) {
            this.chatArchiveRequest.conversationId = this.client.conversationId;
            this.chatArchiveRequest.archived = archived;

            // Following emit use to refresh contacts and archive components when a chat archived/unarchived
            this.contactsService.searchContacts(this.client.chatNumber).subscribe(searchRes => {
              this.contactDetails.emit(searchRes);
            });
      }
    });
    
  }

  /**
   * Use to update unarchived chat to archive during message receive if user in a different conversation
   * @param message 
   */
  updateChatArchiveStatus(message: BaseMessage) {
    this.chatArchiveRequest.conversationId = message.conversationId;
    this.chatArchiveRequest.archived = message.archived;
  }

}
