<div class="content-wrapper">
  <main>
    <div class="layout">
      <!-- Start of Navigation -->
      <div class="navigation">
        <div class="container">
          <div class="inside">
            <div class="nav nav-tab menu">
              <button class="btn"><img class="avatar-xl" src="./../assets/img/avatars/avatar-male-1.jpg"
                  alt="avatar"></button>
              <a href="#members" data-toggle="tab"><i class="material-icons">account_circle</i></a>
              <a href="#discussions" data-toggle="tab" class="active"><i
                  class="material-icons active">chat_bubble_outline</i></a>
              <a href="#notifications" data-toggle="tab" class="f-grow1"><i
                  class="material-icons">notifications_none</i></a>
            </div>
          </div>
        </div>
      </div>
      <!-- End of Navigation -->
      <!-- Start of Sidebar -->
      <div class="sidebar" id="sidebar">
        <div class="container">
          <div class="col-md-12">
            <div class="tab-content">
              <!-- Start of Contacts -->
              <div class="tab-pane fade" id="members">
                <div class="search">
                  <form class="form-inline position-relative">
                    <input type="search" class="form-control" id="people" placeholder="Search for people...">
                    <button type="button" class="btn btn-link loop"><i class="material-icons">search</i></button>
                  </form>
                  <button class="btn create" data-toggle="modal" data-target="#exampleModalCenter"><i
                      class="material-icons">person_add</i></button>
                </div>
                <div class="list-group sort">
                  <button class="btn filterMembersBtn active show" data-toggle="list" data-filter="all">All</button>
                  <button class="btn filterMembersBtn" data-toggle="list" data-filter="online">Online</button>
                  <button class="btn filterMembersBtn" data-toggle="list" data-filter="offline">Offline</button>
                </div>
                <div class="contacts">
                  <h1>Contacts</h1>
                  <div class="list-group" id="contacts" role="tablist">
                    <a href="#" class="filterMembers all online contact" data-toggle="list">
                      <img class="avatar-md" src="./../assets/img/avatars/avatar-male-1.jpg" data-toggle="tooltip"
                        data-placement="top" title="Michael" alt="avatar">
                      <div class="status">
                        <i class="material-icons online">fiber_manual_record</i>
                      </div>
                      <div class="data">
                        <h5>Michael Knudsen</h5>
                        <p>Washington, USA</p>
                      </div>
                      <div class="person-add">
                        <i class="material-icons">person</i>
                      </div>
                    </a>

                  </div>
                </div>
              </div>
              <!-- End of Contacts -->
              <!-- Start of Discussions -->
              <div id="discussions" class="tab-pane fade active show">
                <div class="search">
                  <form class="form-inline position-relative">
                    <input type="search" class="form-control custom-input" id="conversations"
                      placeholder="Search for conversations..." (keyup)="searchTrigger($event)">
                    <button type="button" class="btn btn-link loop"><i class="material-icons">search</i></button>
                  </form>
                  <button class="btn create" data-toggle="modal" (click)="openNewWelcomePopup()"><i
                      class="material-icons">create</i></button>
                </div>
                <div class="list-group sort">
                  <button class="btn filterDiscussionsBtn active show" data-toggle="list" data-filter="all">All</button>
                  <button class="btn filterDiscussionsBtn" data-toggle="list" data-filter="read">Read</button>
                  <button class="btn filterDiscussionsBtn" data-toggle="list" data-filter="unread">Unread</button>
                </div>

                <tabset #staticTabs>
                  <tab heading="Contacts" [active]="contactActive">
                    <app-contact-manager (contactClicked)="navigateChat($event)" [contactDetails]="contactDetails"></app-contact-manager>
                  </tab>
                  <tab heading="Search" [active]="searchActive">
                    <div class="discussions">
                      <h1>Search</h1>
                      <div class="list-group" id="chats" role="tablist">
                        <h4 *ngIf="searchedContacts.length > 0">Contacts</h4>
                        <a *ngFor="let contact of searchedContacts" href="#list-empty"
                          class="filterDiscussions all unread single" id="list-empty-list" data-toggle="list" role="tab"
                          (click)="searchContactSelected(contact)">

                          <img class="avatar-md profile-avatar" [src]="contact.avatar" data-toggle="tooltip"
                            data-placement="top" title="Michael" alt="avatar">
                          <!-- <div class="status">
                            <i class="material-icons offline">fiber_manual_record</i>
                          </div> -->
                          <div *ngIf="contact.unreadCount" class="new bg-pink">
                            <span>+{{contact.unreadCount}}</span>
                          </div>
                          <div class="data">
                            <!-- {{contact|json}} -->
                            <p>{{contact.firstName}} {{contact.lastName}}</p>
                            <h5>{{contact.chatNumber}}</h5>
                            <!-- <span>Sun</span> -->
                            <!-- <p>{{contact.lastMessage}}</p> -->
                          </div>
                        </a>

                      </div>

                      <div>
                        <h4 *ngIf="searchedMessages.length > 0">Messages</h4>

                        <a *ngFor="let clientAndMessage of searchedMessages" href="#list-empty"
                          class="filterDiscussions all unread single" id="list-empty-list" data-toggle="list" role="tab"
                          (click)="searchContactSelected(clientAndMessage.client)">
                          <img class="avatar-md profile-avatar" [src]="clientAndMessage.client.avatar"
                            data-toggle="tooltip" data-placement="top" title="Michael" alt="avatar">
                          <div class="data message-search">
                            <h5>{{clientAndMessage.client.firstName}} {{clientAndMessage.client.lastName}}</h5>
                            <span>{{clientAndMessage.message?.time ? (clientAndMessage.message.time |
                              date:'shortDate'):''}}</span>
                            <p
                              [innerHTML]="clientAndMessage.message?.content ? (clientAndMessage.message.content | highlight:searchTerm) : ''">
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </tab>
                  <tab heading="Archive" [active]="archiveActive">
                    <app-archive-manager (contactClicked)="navigateChat($event)" [contactDetails]="contactDetails"></app-archive-manager>
                  </tab>
                </tabset>

              </div>
              <!-- End of Discussions -->
              <!-- Start of Notifications -->

              <!-- End of Notifications -->
            </div>
          </div>
        </div>
      </div>


      <!-- chat component -->
      <app-chat-box (contactDetails)="passArchivedContacts($event)"></app-chat-box>
      <!-- end of chat component -->

    </div>
  </main>
</div>
