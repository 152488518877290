import { ChatStarterComponent } from './../components/chat-starter/chat-starter.component';
import { ChatBoxService } from './../services/chatbox.service';
import { BaseMessage } from './../entity/models/base-message';
import { AuthSocketService } from './../services/auth-socket.service';
import { AuthService } from './../services/auth.service';
import { TemplateService } from './../services/template.service';
import { TemplateData } from './../entity/models/template-data';
import { Client } from './../entity/models/user';
import { MatchedMessage } from './../entity/models/matched-message';
import { TemplateContent } from './../entity/models/templateContent';
import { Template } from './../entity/models/template';
import { TemplateEditorComponent } from './../components/template-editor/template-editor.component';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ChatItemComponent } from './../chat/chat-item/chat-item.component';
import { PaginationResultDto } from './../entity/models/dto/pagination-contact';
import { ContactsService } from './../services/contacts.service';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MessageRepoService } from './../services/message-repo.service';
import { Conversation } from './../entity/models/conversation';
import { UtilHelper } from './../helpers/util-helper';
import { MessageRepo } from './../entity/models/message-repo';
import { UserService } from './../services/user.service';
import { ApiResponse } from './../entity/models/api-response';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Attachment } from './../entity/models/attachment';
import { DOCUMENT_TYPES } from './../entity/enums/document-types';
import { getTestBed } from '@angular/core/testing';
import { MessageService } from './../services/message.service';
import { MediaService } from './../services/media.service';
import { VideoHelper } from './../helpers/video-helper';
import { FileTypeHelper } from './../helpers/file-helper';
import { MediaResource, UPLOADSTATUS } from './../entity/models/media-resource';
import { Component, OnInit, ViewChild, ElementRef, HostListener, QueryList, ViewChildren } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChatViewService } from './chat-view.service';
import { MessageRequest } from '../entity/models/messsage-request';
import { MessageStatus } from '../entity/enums/message-status';
import { Message } from '../entity/models/message';
import { MessageDirection } from '../entity/enums/message-direction';
import { FileService } from '../services/file.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';
import { forkJoin, Subject } from 'rxjs';
import { ModalDirective, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PickerComponent, PickerModule } from '@ctrl/ngx-emoji-mart';
import { ActivatedRoute, Router } from '@angular/router';
import { MESSAGE_TYPES } from '../entity/enums/message-types';
import { ContactManagerComponent } from '../components/contact-manager/contact-manager.component';
import { MessageContentTemplate } from '../entity/models/message-content/message-content-template';
import { CHANNELS } from '../entity/enums/channels';
import { ChatArchiveRequest } from '../entity/models/chat-archive-request';
import { ArchiveManagerComponent } from '../components/archive-manager/archive-manager.component';
import { ChatBoxComponent } from '../chat-box/chat-box.component';

declare let $: any;

@Component({
  selector: 'app-chat-view',
  templateUrl: './chat-view.component.html',
  styleUrls: ['./chat-view.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ChatViewComponent implements OnInit {


  public curTime: Date = new Date();
  curDate: Date = new Date();
  formattedDate: any;
  pipe = new DatePipe('en-US');
  dateChangeStatus = 1;


  $searchTerm = new Subject<string>();
  searchTerm: string = "";
  searchedContacts: BaseMessage[] = [];
  searchedMessages: MatchedMessage[] = [];

  searchActive: boolean = false;
  contactActive: boolean = true;
  @ViewChild('scrollMe', { static: false, read: ElementRef }) messageScroller: ElementRef;
  scrollerTimer;

  flyRead = 0;
  showFlyCounter = false;

  @ViewChild(ContactManagerComponent) contactManager: ContactManagerComponent;
  @ViewChild(ArchiveManagerComponent) archiveManager: ArchiveManagerComponent;
  @ViewChild(ChatBoxComponent) chatBoxComponent: ChatBoxComponent;
  welcomeMessageModelReference: BsModalRef;
  private messageRecieveSubscription: any;
  archiveActive: boolean = false;
  chatArchiveRequest: ChatArchiveRequest;
  contactDetails: Client;
  archivedContacts: Client[] = [];


  // fix de- prefixed lines cause by backend-frontend model syncing
  constructor(
    private templateService: TemplateService,
    private formBuilder: FormBuilder,
    public ChatService: ChatViewService,
    private fileService: FileService,
    private toastr: ToastrService,
    private messageService: MessageService,
    private userService: UserService,
    public messageRepoService: MessageRepoService,
    private contactsService: ContactsService,
    private modalService: BsModalService,
    private route: Router,
    private aRoute: ActivatedRoute,
    private authSocketService: AuthSocketService,
    private chatBoxService: ChatBoxService) {



    /**
     * listen for new messages
     */

    /**
     * listen for sent message status:[read,sent,delivered...] and update
     */
    this.statusMessageListner();


    /**
    * listen for contact search
    */
    this.contactSearchListner();


    this.PLUSreceiveMessageListner();


    this.receiveMessageListner();

  }


  ngOnInit(): void {
    this.formattedDate = this.pipe.transform(this.curDate, 'dd-MMM-yyyy');
  }

  ngOnDestroy() {
    this.messageRecieveSubscription?.unsubscribe();
  }

  openNewWelcomePopup() {
    this.welcomeMessageModelReference = this.modalService.show(ChatStarterComponent, {
      class: 'test',
      // initialState: {
      //   contact: contact
      // }
    });
    this.welcomeMessageModelReference.content.closeBtnName = 'Close';
    this.welcomeMessageModelReference.content.sendWelcomeRequest.subscribe((value) => {
      console.log("contact updated ", value);
      // this.messageTemplate = value;
      // this.sendMessage();
      this.welcomeMessageModelReference.hide();
      this.sendMessage(value);
    });
  }

  searchTrigger($event) {
    this.$searchTerm.next($event);
  }

  contactSearchListner() {
    this.$searchTerm.pipe(map((e: any) => {
      console.log('e ', e);
      this.searchTerm = e.target.value;
      return e.target.value;
    }),
      debounceTime(400),
      distinctUntilChanged()).subscribe(res => {

        if (res.length > 0) {
          this.searchActive = true;
          this.contactActive = false;
          console.log("searched ", res);

          this.searchContacts(res).subscribe(searchRes => {
            console.log("search res ", searchRes);
            this.searchedContacts = searchRes as BaseMessage[];
          });

          this.searchMessages(res).subscribe(searchRes => {
            console.log("search res ", searchRes);
            this.searchedMessages = searchRes as MatchedMessage[];
          });

        } else {
          this.searchActive = false;
          this.contactActive = true;
          this.searchedContacts = [];
          this.searchedMessages = [];
        }

      });
  }

  searchContacts(searchPhone: string) {
    return this.contactsService.searchContacts(searchPhone);
  }

  searchMessages(searchText: string) {
    return this.ChatService.searchMessages(searchText);
  }



  PLUSreceiveMessageListner() {
    this.ChatService.receiveMessage().subscribe((message: BaseMessage) => {
      console.log('incomming socket ', message);
      // is message for active chatbox client?
      const client = this.chatBoxService.getActiveClient();
      if (client.chatNumber === message.chatNumber) {
        this.chatBoxService.messageReceived(message);
      }
    });
  }

  receiveMessageListner() {
    this.messageRecieveSubscription = this.ChatService.receiveMessage().subscribe((data: any) => {
      this.contactManager.receiveMessage(data, this.archivedContacts);
      this.archiveManager.receiveMessage(data);
      this.chatBoxComponent.updateChatArchiveStatus(data);
    });
  }

  statusMessageListner() {
    this.ChatService.outboundMessageStatus().subscribe((message: BaseMessage) => {
      const client = this.chatBoxService.getActiveClient();
      if (client.chatNumber === message.chatNumber) {
        this.chatBoxService.messageStatusReceived(message);
      }
    });
  }


  updateContactUpTime(contact: Client, time: Date): void {
    if (contact) {
      contact.lastUpTime = time;
    }
  }

  updateReadCount(contact: Client): void {
    if (contact) {
      // contact.unreadCount++;
    }
  }

  resetReadCount(contact: Client): void {
    if (contact) {
      // contact.unreadCount = 0;
    }
  }

  updateUnreadMessage(contact: Client, message: string): void {
    if (contact) {
      const trimmedMessage = UtilHelper.trimString(message, 10);
      // contact.lastMessage = trimmedMessage + ' ...';
    }
  }

  navigateChat(chatId: number) {
    this.route.navigate([chatId + ''], { relativeTo: this.aRoute });
  }

  // onScroll() {
  //   // console.log('scrolled!! ', this.messageScroller.nativeElement.scrollHeight - this.messageScroller.nativeElement.scrollTop);
  //   const isInside = this.isLastMessageInsideChatView();
  //   this.showHideFlyMessageCounter(!isInside);
  //   if (isInside) {
  //     this.resetFlyCount();
  //   }
  //   // console.log("isInside ", isInside);
  // }

  showHideFlyMessageCounter(show: boolean) {
    this.showFlyCounter = show;
  }


  playMessageReceivedMusic(): void {
    this.messageService.playMusic();
  }

  searchContactSelected(client: Client) {
    this.navigateChat(client.userId);
  }

  navigateToProfilePage(contact: Client) {
    console.log("conversationId ", contact.conversationId);
    this.route.navigate(['/profile', contact.userId]);
  }

  loadNewMessages() {
    console.log("load more  messages ... ");
  }

  getNumber(): string {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }


  sendMessage(formData: any) {
    // temp
    const message = new BaseMessage();
    message.type = MESSAGE_TYPES.TEMPLATE;
    const templateContent = new MessageContentTemplate();
    templateContent.templateId = formData.templateId;
    templateContent.templateName = formData.templateName;
    (<MessageContentTemplate>message.content) = templateContent;
    message.status = MessageStatus.PENDING;
    message.chatNumber = formData.phone.e164Number;
    message.channel = CHANNELS.WHATSAPP;
    message.direction = MessageDirection.SENT;

    this.ChatService.sendMessage(message).subscribe((res: any) => {
      console.log('saved message id  ', res.messageId);
      if (res.clientId) {
        this.navigateChat(res.clientId);
      } else {
        this.toastr.error('Error occured', '');
      }
    }, err => {
      message.status = MessageStatus.FAILED;
    });

  }

  /**
   * Pass chat details to contacts and archive components when relevant chat been archived/unarchived
   * @param contactDetails 
   */
  passArchivedContacts(contactDetails: Client) {
    this.contactDetails = contactDetails;

    // create separate list of archived contacts (WF-3497)
    if (contactDetails[0].archived) {
      if (this.archivedContacts) {
        this.archivedContacts = _.filter(this.archivedContacts, function(contact) { return contact.conversationId != contactDetails[0].conversationId; }); // remove duplicates
      }
      this.archivedContacts.unshift(contactDetails[0]);
    }
  }

}
