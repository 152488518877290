import { Profile } from './../../entity/models/profile';
import { environment } from './../../../environments/environment';
import { ProfileService } from './../../services/profile.service';
import { Client } from './../../entity/models/user';
import { ApiResponse } from './../../entity/models/api-response';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContactsService } from 'src/app/services/contacts.service';
import { ActivatedRoute } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TemplateData } from 'src/app/entity/models/template-data';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  profileForm: FormGroup;
  submitted = false;
  coversationId: number;
  profileId: number;
  profile: Profile;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  contact:Client;
  @Output() profileUpdated: EventEmitter<Client> = new EventEmitter();
  imgFile: string;

  constructor(private formBuilder: FormBuilder,
    private contactService: ContactsService,
    private profileService: ProfileService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.initForm();
    this.routeListener();
  }

  async routeListener() {
      this.coversationId = this.contact.conversationId;
      this.profileId = this.contact.userId;
      console.log("this.coversationId ", this.coversationId);
      this.profileForm.patchValue({ coversationId: this.coversationId });
      await this.fetchProfile();
      this.fillProfileForm();
  }

  private initForm(): void {
    this.profileForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      userId: ['', [Validators.required]],
      imgSrc: ['', [Validators.required]]
    });
  }


  public async fetchProfile() {
    this.profile = await this.profileService.getProfile(this.profileId).toPromise();
    console.log("this.profile ", this.profile);
    this.profileService.getProfile(this.profileId);
  }

  public fillProfileForm() {
    // this.profile.pic = `${environment.UPLOAD_URL}/profile-${this.profile.phone.replace('+', '')}.png`;

    this.profileForm.setValue({
      firstName: this.profile.firstName,
      lastName: this.profile.lastName,
      phone: this.profile.phone,
      userId: this.profileId,
      imgSrc:null
    });
  }


  public updateProfile(): void {
    this.submitted = true;
    if (this.profileForm.invalid) {
      this.toastr.error('Please fill the form', 'Profile');
      return;
    }

    const formData = this.profileForm.value;
    console.log('form data ', formData);
    this.profileService.updateProfile(formData).subscribe((res: ApiResponse) => {
      if (res.status) {
        this.toastr.success('Updated successfully', 'Profile');
        this.profileUpdated.emit(null);
        this.modalService.hide();
      } else {
        this.toastr.error('Update Failed ', 'Profile');
      }
    });
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onImageChange(e) {
    const reader = new FileReader();

    if(e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.imgFile = reader.result as string;
        this.profileForm.patchValue({
          imgSrc: reader.result
        });
      };
    }
  }


  get formControls(): any {
    return this.profileForm.controls;
  }

}
