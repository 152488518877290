<div class="file-preview">

  <button type="button" class="close-media btn" aria-label="Close" (click)="removeMedia()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="preview-wrapper">
    <div class="img-view" *ngIf="resource.docType==DOCUMENT_TYPES.IMAGE &&  resource.localUrl"
      [ngStyle]="{'background-image': 'url('+ resource.localUrl + ')'}">
    </div>

    <div class="resource-audio" *ngIf="resource.docType==DOCUMENT_TYPES.AUDIO">
      <span class="material-icons">audiotrack</span>
    </div>

    <div class="resource-video" *ngIf="resource.docType==DOCUMENT_TYPES.VIDEO"
      [ngStyle]="{'background-image': (resource.thubnail)?'url('+ resource.thubnail + ')':'none'}">
      <span class="material-icons">movie</span>

    </div>

  </div>


  <div class="loader-view">
    <app-loader *ngIf="resource.status==UPLOADSTATUS.PENDING"></app-loader>
  </div>
</div>
