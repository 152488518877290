import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

export function templateContentValiadater(formControl: AbstractControl) {
  if (!formControl.parent) {
    return null;
  }
  if (formControl.parent.get('isWelcome').value) {
    const content = formControl.parent.get('content').value;
    let curlyRegex = /{\{\d+\}\}/g;
    const containPlaceholders = curlyRegex.test(content);
    console.log('containPlaceholders ',containPlaceholders);
    return containPlaceholders ? ({ 'placeholderContent': { value: content } }) : null;
  }
  return null;
}
