<!-- End of Create Chat -->
<div class="main">

  <div *ngIf="!empty" class="chat" id="chat1">
    <div class="top">
      <div class="container">
        <div class="col-md-12">
          <div class="inside">
            <a href="#">
              <img class="avatar-md" [src]="client?.avatar" data-toggle="tooltip" data-placement="top" title="Keith"
                alt="avatar">
            </a>
            <div class="status channel-preview" [ngClass]="[client?.channelName + '-channel']">
              <i class="online"></i>
            </div>
            <div class="data">
              <!-- <h5><a href="#">{{activeMessageRepo.user.phone}}</a></h5> -->
              <span>{{(client?.firstName) ? client?.firstName :
                client?.chatNumber}}</span>
                <span>{{client?.chatNumber}}</span>
            </div>
            <div class="dropdown">
              <button class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                  class="material-icons md-30">more_vert</i></button>
              <div class="dropdown-menu dropdown-menu-right">
                <button class="dropdown-item" (click)="archiveChat(true)" [hidden]="(chatArchiveRequest?.conversationId == client?.conversationId && chatArchiveRequest?.archived == true) ? true : (chatArchiveRequest?.conversationId == client?.conversationId && chatArchiveRequest?.archived == false) ? false : client?.archived"><i class="material-icons">archive</i>Archive Chat</button>
                <button class="dropdown-item dropdown-unarchive" (click)="archiveChat(false)" [hidden]="(chatArchiveRequest?.conversationId == client?.conversationId && chatArchiveRequest?.archived == true) ? false : (chatArchiveRequest?.conversationId == client?.conversationId && chatArchiveRequest?.archived == false) ? true : !client?.archived"><i class="material-icons">unarchive</i>Unarchive Chat</button>
                <!--<button class="dropdown-item"><i class="material-icons">clear</i>Clear History</button>
                <button class="dropdown-item"><i class="material-icons">block</i>Block Contact</button>
                <button class="dropdown-item"><i class="material-icons">delete</i>Delete Contact</button>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="content" [ngClass]="{'emoji-view-active': emojViewActive}" id="content" infiniteScroll
      [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolledUp)="messageScroll()"
      #scrollMe (scroll)="onScroll()">

      <div class="container">

        <div class="col-md-12  message-container">
          <div class="spinner-wrap">
            <ngx-spinner bdColor = "transparent" size = "medium" color = "#2fe7ce" type = "ball-clip-rotate" [fullScreen] = "false"><p style="color: rgb(0, 0, 0)" > Loading... </p></ngx-spinner>
          </div>
          <div *ngFor="let message of chatBoxService.messagesList">
            <app-chat-item [message]="message" [client]="client"></app-chat-item>
          </div>
          <!-- <div class="read-fab" *ngIf="showFlyCounter && flyRead>0" [@fadeInOut]>
            <div class="read-wrapper">
              <div class="read-counter">
                <span class="counter-text">{{flyRead}}</span>
              </div>
              <button type="button" (click)="scrollToBottom()" class="btn btn-light btn-circle btn-md fab-btn shadow">
                <i class="material-icons">
                  expand_more
                </i>
              </button>
            </div>

          </div> -->

        </div>
      </div>
    </div>

    <emoji-mart *ngIf="emojViewActive" emojiSize="30" set="facebook" [style]="{ width: '100%' }"
      (emojiSelect)="addEmoji($event)"></emoji-mart>

    <div class="container">
      <div class="col-md-12">
        <div class="file-bar">
          <app-media-preview (mediaRemoved)="removeMedia()" [resource]="media" *ngFor="let media of mediaResource">
          </app-media-preview>
        </div>


        <div class="bottom chat-closed" #typePad *ngIf="!chatBoxService.windowOpen">
          <form class="position-relative w-100">

            <div class="alert alert-primary">
              <div class="row">
                <div class="col-auto align-self-start">
                  <span class="material-icons"> timer_off</span>
                </div>
                <div class="col">
                  <div>
                    You've reached your 24-hour limit on Whatsapp.
                  </div>
                  <button type="button" class="btn btn-primary" (click)="browseTemplates()">Browse
                    Templates</button>
                </div>
              </div>
            </div>
          </form>
        </div>


        <div class="bottom" #typePad *ngIf="!chatWindowClosed">
          <form class="position-relative w-100">
            <textarea #chatInput class="form-control custom-text-area" name="message-text" placeholder="Start typing for reply..."
              rows="1" [(ngModel)]="messageText" (keyup.enter)="sendMessage()"></textarea>
            <button [hidden]="!supportTemplate" class="btn emoticons template-icon" (click)="browseTemplates()">
              <span class="material-icons">
                insert_drive_file
              </span>
            </button>
            <button [hidden]="emojViewActive" class="btn emoticons" (click)="openEmoji()"><i
                class="material-icons">insert_emoticon</i></button>
            <button [hidden]="!emojViewActive" class="btn emoticons" (click)="closeEmoji()"><i
                class="material-icons">close</i></button>
            <button type="button" class="btn send" (click)="sendMessage()"><i class="material-icons">send</i></button>
          </form>
          <label>
            <input name="file" #imageInput type="file" accept="image/*" (change)="processFile(imageInput)">
            <span class="btn attach d-sm-block d-none"><i class="material-icons">attach_file</i></span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="empty">
    <div class="empty-view">
      <app-empty-chat></app-empty-chat>
    </div>
  </div>
  <!-- Start of Babble -->

  <!-- End of Babble -->
</div>
